/* By Athlon Front-End Team */

/* Core variables */
@import 'base/vars';

/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Mixins, helpers and functions */
@import 'base/mixins';

/* Vendor */

/* Components */
@import '../components/stories/stories';
@import '../components/profile/profile';
@import '../components/article/article';
@import '../components/footer/footer';
@import '../components/tape/tape';
@import '../components/hero/hero';
@import '../components/case/case';
@import '../components/embed/embed';
@import '../components/workswiper/workswiper';

/* Browser specific styles */
@import 'dirty/hacks';

/* Demo styles */


body {
  background: $white;
  color: $dark-blue;
  font-family: 'IBM Plex Mono', monospace;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0;

  @include respond-to(medium) {
    font-size: 1rem;
  }
}

* {
  &::selection {
    color: white;
    background: $blue;
  }
}

.canvas-spacer {
  pointer-events: none;
  height: 100vh;
  width: 100vw;
}

.content {
  //margin-top: 100vh;
  width: 100%;
  position: relative;

  &--legal {
    margin-top: 0;
    padding-bottom: 2rem;
  }
}

.showreel {
  width: 100%;
  margin-bottom: 2rem;
  // margin-top: 2rem;
  border-radius: 10px;
  border: 1px solid #efefef;
}

.scrollUpIndicator, .scrollDownIndicator {
  width: 100%;
  color: $black;
  padding: 1rem;
  text-align: left;
  font-size: 12px;
  position: relative;
  display: block;

  @include respond-to(small) {
		font-size: 14px;
	}
}

.scrollDownIndicator {
  text-align: left;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 25px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-bottom: 25px;
  z-index: 300;
}

/*p {
  border-left: 4px solid white;
  padding-left: 1rem;
  width: auto;
  display: inline-block;
  margin-top: 1rem;
}*/



.adjust--noise {
  transform: translate3d(0,0,0);
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 1.5s linear;
}


.adjust--noise div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/noise.png');
  background-size: 500px 500px;
  animation: flip .3s steps(1) infinite;
  opacity: .07;
}

@keyframes flip{
  0%{
      transform:scaleX(1) scaleY(1);
  }
  25%{
      transform:scaleX(1)scaleY(-1);
      }
  50%{
      transform:scaleX(-1) scaleY(-1);
  }
  75%{
      transform:scaleX(-1) scaleY(1);
  }
  100%{
      transform:scaleX(-1) scaleY(1);
  }
}